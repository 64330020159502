import React, { Component } from "react";
import { Accordion, Button } from "react-bootstrap";
// import queryString from 'querystring';
// import { URL } from 'url';
import parserUrl from 'url-parse';

// const { URL } = require('url');

class AddToCalendar extends Component {
    state = {
        accordionOpts: {}
    }

    calendars = [
        { id: 'apple', label: 'Apple Calendar', download: true },
        { id: 'google', label: 'Google ', download: false },
        { id: 'outlook', label: 'Outlook', download: true },
        { id: 'outlook.web', label: 'Outlook Web', download: false },
        { id: 'yahoo', label: 'Yahoo!', download: false },
    ]

    makeTime = time => new Date(time).toISOString().replace(/[-:]|\.\d{3}/g, "");

    makeDuration() {
        const minutes = Math.floor((+new Date(this.props.event.endsAt) - +new Date(this.props.event.startsAt)) / 60 / 1000);
        return `${`0${Math.floor(minutes / 60)}`.slice(-2)}${`0${minutes % 60}`.slice(-2)}`;
    }

    makeUrl(calendar) {
        let address = ''
        let query = {}
        
        const options = this.calendars
            .filter(c => c.id === calendar.id)
            .pop()

        if (options.download === true) {
            const components = [
                "BEGIN:VCALENDAR",
                "VERSION:2.0",
                "BEGIN:VEVENT"
            ];
            
            // In case of SSR, document won't be defined
            if (typeof document !== "undefined") {
                components.push(`URL:${document.URL}`);
            }
            
            components.push(
                `DTSTART:${this.makeTime(this.props.event.startsAt)}`,
                `DTEND:${this.makeTime(this.props.event.endsAt)}`,
                `SUMMARY:${this.props.event.name}`,
                `DESCRIPTION:${this.props.event.details}`,
                `LOCATION:${this.props.event.location}`,
                "END:VEVENT",
                "END:VCALENDAR"
            );

            return encodeURI(`data:text/calendar;charset=utf8,${components.join("\n")}`);
        }

        if (calendar.id === 'google') {
            address = 'https://calendar.google.com/calendar/render'
            query = {
                action: "TEMPLATE",
                dates: `${this.makeTime(this.props.event.startsAt)}/${this.makeTime(this.props.event.endsAt)}`,
                location: this.props.event.location,
                text: this.props.event.name,
                details: this.props.event.details
            }
        }

        if (calendar.id === 'outlook.web') {
            address = 'https://outlook.live.com/owa'
            query = {              
                rru: "addevent",
                startdt: this.props.event.startsAt,
                enddt: this.props.event.endsAt,
                subject: this.props.event.name,
                location: this.props.event.location,
                body: this.props.event.details,
                allday: false,
                uid: new Date().getTime().toString(),
                path: "/calendar/view/Month"
            }
        }

        if (calendar.id === 'yahoo') {
            address = 'https://calendar.yahoo.com'
            query = {
                v: 60,
                view: "d",
                type: 20,
                title: this.props.event.name,
                st: this.makeTime(this.props.event.startsAt),
                dur: this.makeDuration(),
                desc: this.props.event.details,
                in_loc: this.props.event.location
            }
        }

        const url = parserUrl(address, true)
        url.query = query

        return url.toString()
    }

    render() {
        const accordionProps = {}

        if (this.props.variant)
            accordionProps.variant = this.props.variant

        const calendarOptionProps = {
            variant: this.props.optionVariant || 'link',
            className: 'btn btn-block btn-'
        }

        // Set classname for calendar options
        calendarOptionProps.className += calendarOptionProps.variant

        if (this.props.onOpen)
            calendarOptionProps.onClick = this.props.onOpen

        const calendars = this.props.calendars || []

        return (
            <Accordion {...accordionProps}>
                <div {...this.props}>
                    <Accordion.Toggle as={Button} block eventKey="0" variant={accordionProps.variant}>
                        Adicionar ao Calendário
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="0">
                        <div className={this.props.className}>
                            {this.calendars.filter(calendar => calendars.length === 0 || calendars.indexOf(calendar.id) > -1).map((calendar, index) => (
                                <a target="_blank" rel="noreferrer" href={this.makeUrl(calendar, this.props.event)} download={calendar.download} data-calendar={calendar.id} key={index} {...calendarOptionProps}>{calendar.label}</a>
                            ))}
                        </div>
                    </Accordion.Collapse>
                </div>
            </Accordion>
        )
    }
}

export default AddToCalendar;