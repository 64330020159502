import { faBan } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import React, { Component } from "react";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import { CopyToClipboard } from "react-copy-to-clipboard";
import FadeIn from "react-fade-in";
import MetaTags from 'react-meta-tags';
import { Redirect } from "react-router-dom";
import { getLogo } from '../../brandOptions';
import ErrorAlert from "../../components/ErrorAlert";
import Header from "../../components/Header";
import PageLoader from "../../components/PageLoader";
import AddToCalendar from "../../components/AddToCalendar";
import { activities, fetchReminder, storeActivity } from "../../services/api";
import "./styles.scss";

class Reminder extends Component {
    state = {
        customer: {
            name: null
        },
        summary: {
            // Valor da dívida
            amount: null,
            // Número do código de barras
            barcode_number: null,
            // Descrição do acordo/nome da carteira
            description: null,
            // Linha digitável do código de barras
            digitable_barcode_number: null,
            // Data de vancimento
            due_date: null,
            // Valor da parcela
            installment_value: null,
            // Nome do cliente
            customer_name: null,
            
        },
        calendarEvent: {
            name: "Lembrete de pagamento do acordo",
            details: "",
            location: "",
            startsAt: "2018-12-06T17:00:00-05:00",
            endsAt: "2018-12-06T18:00:00-05:00"
        },
        error: {
            show: false,
            content: null
        },
        copied: false,
        redirect: null,
        loading: true
    }

    constructor(props) {
        super(props)
        this.handleOpenCalendar = this.handleOpenCalendar.bind(this)
    }

    getToken() {
        const { match: { params } } = this.props
        return params.token
    }

    async componentDidMount() {
        // Get proposal token from storage
        const token = this.getToken()

        if (token === 'not-found') {
            // Error state
            const error = { show: true, content: "Esta proposta é inválida" }
            
            // Set states for submit button and show the error
            return this.setState({ error, loading: false })
        }

        try {
            const { data } = await fetchReminder(token)

            const summary = {
                // Descrição do acordo/nome da carteira
                description: data.var14,
                // Linha digitável do código de barras
                digitable_barcode_number: data.var11,
                // Data de vancimento
                due_date: data.var13,
                // Valor da parcela
                installment_value: data.var12
            }

            // Nome do cliente
            const customer = { name: data.var6 }

            // Create moment object from due date
            const dueDate = moment(summary.due_date, 'YYYYMMDD')
            dueDate.set({ hours: 8 })

            // Set a formatted due date
            summary.due_date = dueDate.format('DD/MM/YYYY')

            // Calendar event values
            const calendarEvent = {
                name: `Pagamento do acordo: ${summary.description}`,
                details: `Lembrete de vencimento do pagamento do acordo "${summary.description}"`,
                location: "",
                startsAt: dueDate.format('YYYY-MM-DDTHH:mm:ss'),
                endsAt: dueDate.add(2, 'hours').format('YYYY-MM-DDTHH:mm:ss')
            };

            this.setState({ summary, customer, calendarEvent, loading: false })

        } catch (err) {
            console.log('error >> ', err)
            // Get error response status and data
            const { response: { status, data } } = err
            
            // Error state
            const error = { show: true, content: null }

            if (status === 404) {
                error.content = "Não há acordo firmado para esta proposta";
            } else if (status === 400) {
                error.content = "Esta proposta é inválida"
            } else {
                error.content = data.error || "Serviço temporáriamente indisponível"
            }

            // Set states for submit button and show the error
            this.setState({ error, loading: false })
        }
    }

    handleCopyBarcode = () => {
        // Store a user activity
        storeActivity(this.getToken(), activities['copy-digitable-line'])

        this.setState({ copied: true })
    }

    handleOpenCalendar() {
        // Store a user activity
        storeActivity(this.getToken(), activities['create-reminder'])
    }

    renderRedirect = () => {
        if (this.state.redirect !== null)
            return (<Redirect to={this.state.redirect} />)
        else
            return (<></>)
    }

    render = () => {
        if (this.state.redirect !== null)
            return (<Redirect to={this.state.redirect} />)

        if (this.state.loading)
            return <PageLoader />

        return (
            <>
                <MetaTags>
                    <title>Lembrete de pagamento</title>
                    {this.state.error.show === false ? (<>
                        <meta name="description" content={`Lembrete de pagamento do seu acordo com vencimento em ${this.state.summary.due_date}`} />
                        
                        <meta property="og:site_name" content="Lembrete de pagamento"></meta>
                        <meta property="og:description" content={`Lembrete de pagamento do seu acordo com vencimento em ${this.state.summary.due_date}`} />
                        <meta property="og:title" content="Lembrete de pagamento" />
                        <meta property="og:image" content={getLogo(window.location.hostname, 'v')} />
                        <meta property="og:type" content="website" />
                        <meta property="og:url" content={`/${this.getToken()}`} />
                    </>) : (<></>)}
                </MetaTags>
                <FadeIn>
                    <Header />
                    <Card className="shadow-sm border-0">
                        <Container className="py-3">
                            {this.state.error.show
                            ? (<ErrorAlert {...this.state.error} icon={faBan} />)
                            : (<>
                                <Row className="pb-3">
                                    <Col>
                                        <Card.Title>Lembrete do seu acordo</Card.Title>
                                        <Card.Subtitle className="small text-muted">Abaixo as informações da negociação</Card.Subtitle>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col>
                                        <div className="form">
                                            <div className="form-group mb-2">
                                                <div className="small">Cliente</div>
                                                <div>{this.state.customer.name}</div>
                                            </div>
                                            <div className="form-group mb-2">
                                                <div className="small">Número de acordo</div>
                                                <div>{this.state.summary.description}</div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>

                                <hr />
                                <Row className="pb-3">
                                    <Col>
                                        <Card.Title>Boleto</Card.Title>
                                        <Card.Subtitle className="small text-muted">Abaixo as informações do boleto para pagamento</Card.Subtitle>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col>
                                        <div className="form">
                                            <div className="form-group mb-2">
                                                <div className="small">Vencimento</div>
                                                <div>{this.state.summary.due_date}</div>
                                            </div>
                                            <div className="form-group mb-2">
                                                <div className="small">Valor da parcela</div>
                                                <div>R$ {this.state.summary.installment_value}</div>
                                            </div>
                                            <div className="form-group mb-2">
                                                <div className="small">Linha digitável</div>
                                                <div>{this.state.summary.digitable_barcode_number}</div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                
                                <Row>
                                    <Col className="mt-2">
                                        <Button block variant="primary" onCopy={this.handleCopyBarcode} text={this.state.summary.digitable_barcode_number} as={CopyToClipboard}>
                                            <span>{!this.state.copied ? "Copiar linha digitável" : "Copiado!"}</span>
                                        </Button>

                                        <AddToCalendar className="mt-2" event={this.state.calendarEvent}
                                            onOpen={this.handleOpenCalendar}
                                            calendars={['apple', 'google', 'outlook', 'outlook.web', 'yahoo']} />
                                    </Col>
                                </Row>
                            </>)}
                        </Container>
                    </Card>
                </FadeIn>
            </>
        )
    }
}

export default Reminder